/* eslint-disable prettier/prettier */
import React, { Component, Fragment } from "react";
import photos from "./photos.json";
import videos from "./videos.json";
import PhotosData from "./photosdata";
import VideosData from "./videosdata";
import { Tab, Nav } from "react-bootstrap";

class MujibCorner extends Component {
    constructor (props) {
        super(props);
        this.state = {
            data1: photos,
            data2: videos,
        };
    }

    render () {
        return (
            <Fragment>
                <div className='section'>
                    <div className='container'>
                        <Tab.Container defaultActiveKey='tab1'>
                            <div className='text-center filter-items-1'>
                                <Nav
                                    as='ul'
                                    varient='tabs'
                                    style={{ justifyContent: "center" }}
                                >
                                    <Nav.Item as='li'>
                                        <Nav.Link eventKey='tab1'>
                                            Photo Gallery
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as='li'>
                                        <Nav.Link eventKey='tab2'>
                                            Video Gallery
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <Tab.Content>
                                <Tab.Pane eventKey='tab1'>
                                    <div className='row'>
                                        <PhotosData data={this.state.data1} />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey='tab2'>
                                    <div className='row'>
                                        <VideosData data={this.state.data2} />
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default MujibCorner;

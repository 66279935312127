import React from "react";
import Card from "react-bootstrap/Card";

const Data = ({ data }) => {
    const Data = (
        <div className="row">
            <p style={{ fontSize: "18px", color: "#000", marginBottom: "0px" }}>
                জাতির জনক বঙ্গবন্ধু শেখ মুজিবুর রহমানের জন্মশতবার্ষিকী উপলক্ষে
                ২০২০ ও ২০২১ সালকে ‘মুজিব বর্ষ’ হিসেবে উদযাপনের সিদ্ধান্ত নিয়েছে
                সরকার। এই উপলক্ষে ২০২০ সালের ১৭ মার্চ থেকে ২০২১ সালের ১৭ মার্চ
                পর্যন্ত বছরব্যাপী নানা অনুষ্ঠান ও কর্মসূচী পালন করা হবে
                দেশব্যাপী।
            </p>
            <p style={{ fontSize: "18px", color: "#000" }}>
                উল্লেখ্য, ২০২০ সালে পূর্ণ হবে বাংলাদেশের স্বাধীনতার মহান স্থপতি
                জাতির পিতা বঙ্গবন্ধু শেখ মুুজিবুর রহমানের জন্মের শত বছর। আর ২০২১
                সাল হবে বাংলাদেশের স্বাধীনতার সুবর্ণজয়ন্তী বছর।
            </p>
            {data.map((item, i) => {
                return (
                    <div
                        className="col-lg-4 col-md-6 col-sm-12"
                        key={i}
                        style={{ marginBottom: "20px", marginTop: "20px" }}
                    >
                        <a href={item.video}>
                            <Card style={{ width: "30rem" }}>
                                <iframe
                                    title={item.id}
                                    src={item.video}
                                    style={{ height: "20rem" }}
                                ></iframe>
                                <Card.Title
                                    style={{
                                        fontSize: "20px",
                                        margin: "0px",
                                    }}
                                >
                                    {item.title}
                                </Card.Title>
                            </Card>
                        </a>
                    </div>
                );
            })}
        </div>
    );
    return Data;
};

export default Data;
